import { BaseDocument } from '../types';
import { Gradient, QuoteshotAspectRatio, QuoteshotFont, ThemeVariant } from '../types/quoteshots';
import makeLogger from '../utils/makeLogger';
import {
  AUTHOR_TEXT_HEIGHT,
  AUTHOR_TOP_MARGIN,
  BOTTOM_MARGIN,
  HYPER_HIGHLIGHT_X_PADDING,
  MAX_FONT_SIZE,
  PIXEL_RATIO,
  SECONDARY_FONT,
  TEXT_OFFSET_X,
  TITLE_BOTTOM_MARGIN,
  TITLE_TEXT_HEIGHT,
  TOP_MARGIN,
} from './constants';
import { HighlightBlock, scanAllHighlightTextBlocks, scanForTextBlocks } from './textHelpers';


const logger = makeLogger(__filename);

export const getThemePropertyWithDarkMode = <T extends keyof ThemeVariant>(theme: ThemeVariant, propertyName: T, isDarkMode: boolean): ThemeVariant[T] => {
  let key: string = propertyName;
  if (isDarkMode) {
    key = `dark${propertyName.charAt(0).toUpperCase() + propertyName.slice(1)}`;
  }
  return theme[key] ?? theme[propertyName];
};

export const setThemePropertyWithDarkMode = <T extends keyof ThemeVariant>(theme: ThemeVariant, propertyName: T, newValue: ThemeVariant[T], isDarkMode: boolean) => {
  let key: string = propertyName;
  if (isDarkMode) {
    key = `dark${propertyName.charAt(0).toUpperCase() + propertyName.slice(1)}`;
  }
  if (theme[key]) {
    theme[key] = newValue;
    return;
  }
  theme[propertyName] = newValue;
};


export type CanvasImageHighlight = {
  id: string;
  author: BaseDocument['author'];
  title: BaseDocument['title'];
  text: string;
};
export class CanvasImageGenerator {
  static generateGradientOrColor = (context: CanvasRenderingContext2D, color: Gradient | string, width: number, height: number) => {
    if (typeof color === 'string') {
      return color;
    }
    const angle = (color.angle ?? 45) * Math.PI / 180;
    const x2 = width * Math.cos(angle);
    const y2 = height * Math.sin(angle);
    const grd = context.createLinearGradient(0, 0, x2, y2);
    grd.addColorStop(color.start.offset, color.start.color);
    grd.addColorStop(color.end.offset, color.end.color);
    return grd;
  };

  static computeLineHeight(fontSize: number) {
    const roundedFontSize = Math.round(fontSize);
    if (roundedFontSize <= 16) {
      return 2;
    }
    if (roundedFontSize <= 30) {
      return 1.9;
    }
    return 1.7;
  }

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  theme: ThemeVariant;
  highlight: CanvasImageHighlight;
  canvasWidth: number;
  canvasHeight: number;
  ratio: number;
  isDarkMode: boolean;
  primaryFontSize: number;
  debugMode = false;
  currentFont: string;
  maxTextWidth = 0;


  constructor(canvas: HTMLCanvasElement, theme: ThemeVariant, highlight: CanvasImageHighlight, canvasWidth: number, ratio: number, isDarkMode: boolean, currentFont: QuoteshotFont) {
    this.canvas = canvas;
    if (!canvas) {
      throw new Error('No canvas found');
    }
    const context = canvas.getContext('2d');
    if (!context) {
      throw new Error('No canvas context found');
    }
    this.context = context;
    this.theme = theme;
    this.highlight = highlight;
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasWidth / ratio;
    this.ratio = ratio;
    this.isDarkMode = isDarkMode;
    this.primaryFontSize = 10;
    this.maxTextWidth = this.canvasWidth - TEXT_OFFSET_X * 2;
    this.currentFont = currentFont;
  }

  get authorTopMargin() {
    if (this.ratio === QuoteshotAspectRatio.Landscape) {
      return 16;
    }
    return AUTHOR_TOP_MARGIN;
  }

  get authorSectionHeight() {
    return BOTTOM_MARGIN + AUTHOR_TEXT_HEIGHT + this.authorTopMargin;
  }

  get titleBottomMargin() {
    if (this.ratio === QuoteshotAspectRatio.Landscape) {
      return 16;
    }
    return TITLE_BOTTOM_MARGIN;
  }

  get minimumCharactersPerLine() {
    if (this.highlight.text.length < 50) {
      return 0;
    }
    if (this.highlight.text.length < 150) {
      return 15;
    }
    return 20;
  }

  getPrimaryFontStyle(fontSize: number, extras: HighlightBlock['style'] = {}) {
    let fontName = this.currentFont;
    if (this.theme.fontOverrides && this.currentFont in this.theme.fontOverrides) {
      // Need to do this step for typescript to not complain about undefined values
      const fontOverride = this.theme.fontOverrides[this.currentFont as QuoteshotFont];
      fontName = fontOverride ?? this.currentFont;
    }
    if (extras.bold) {
      return `bold ${fontSize}px ${fontName}`;
    }
    if (extras.italic) {
      return `500 italic ${fontSize}px ${fontName}`;
    }
    return `500 ${fontSize}px ${fontName}`;
  }

  // eslint-disable-next-line sort-class-members/sort-class-members
  get titleSectionHeight() {
    return TOP_MARGIN + TITLE_TEXT_HEIGHT + this.titleBottomMargin;
  }


  // Wipe the canvas and rescale it
  resetCanvas() {
    // Wipe
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.canvas.width = 0;
    this.context.canvas.height = 0;

    // rescale
    const ratio = PIXEL_RATIO;
    this.canvas.width = this.canvasWidth * ratio;
    this.canvas.height = this.canvasHeight * ratio;
    this.context.setTransform(ratio, 0, 0, ratio, 0, 0);
  }

  getThemePropertyWithDarkMode<T extends keyof ThemeVariant>(propertyName: T): ThemeVariant[T] {
    return getThemePropertyWithDarkMode<T>(this.theme, propertyName, this.isDarkMode);
  }

  getPrimaryTextHeightOnCanvas (fontSize: number) {
    const oldFont = this.context.font;
    this.context.font = this.getPrimaryFontStyle(fontSize);
    const metrics = this.context.measureText('MMMM');
    const textHeightForLine = (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent) * CanvasImageGenerator.computeLineHeight(fontSize);
    this.context.font = oldFont;
    return textHeightForLine;
  }


  // This function takes a maxHeight and returns the optimal font size for the given text
  // We start from a small font and work our way up until we can fit all the text inside the
  // bounds of the text area defined by width and height
  // We will stop after the text overflows
  fontSizeLoopHelper(maxHeight: number, step: number, maxFontSize: number) {
    let fontSize = 5;
    let font = this.getPrimaryFontStyle(fontSize);
    let lines = scanForTextBlocks(this.canvas, this.highlight.text, this.maxTextWidth, font);
    let numberOfLines = lines[lines.length - 1].lineNumber + 1;
    let totalTextHeight = 0;
    while (totalTextHeight < maxHeight) {
      fontSize += step;
      font = this.getPrimaryFontStyle(fontSize);
      if (fontSize > maxFontSize) {
        break;
      }
      lines = scanForTextBlocks(this.canvas, this.highlight.text, this.maxTextWidth, font);
      numberOfLines = lines[lines.length - 1].lineNumber + 1;

      if (!lines.length) {
        break;
      }
      const textHeightForLine = this.getPrimaryTextHeightOnCanvas(fontSize);
      totalTextHeight = textHeightForLine * numberOfLines;
      // If the average chars per line is less than threshold, break
      if (numberOfLines > 1 && this.highlight.text.length / numberOfLines < this.minimumCharactersPerLine) {
        break;
      }
    }
    return fontSize - step;
  }

  findOptimalFontSize() {
    const height = this.canvasHeight - this.titleSectionHeight - this.authorSectionHeight - 24 / this.ratio;
    const potentialFontSize = this.fontSizeLoopHelper(height, 1, MAX_FONT_SIZE);
    const fineTunedFontSize = this.fontSizeLoopHelper(height, 0.1, potentialFontSize + 1);
    return fineTunedFontSize - 0.1;
  }

  setCanvasFillStyle() {
    // Sets the fill style of the canvas
    // this sets the color of any text we draw on the canvas
    const textColor = this.getThemePropertyWithDarkMode('textColor');
    this.context.fillStyle = CanvasImageGenerator.generateGradientOrColor(this.context, textColor, this.canvasWidth, this.canvasHeight);
  }

  fillBackgroundOnCanvas() {
    const canvas = this.canvas;
    const context = this.context;
    context.fillStyle = '#FFFFFF';
    context.fillRect(0, 0, canvas.width, canvas.height);
    const backgroundColor = this.getThemePropertyWithDarkMode('backgroundColor');
    // Fill with gradient
    context.fillStyle = CanvasImageGenerator.generateGradientOrColor(this.context, backgroundColor, this.canvasWidth, this.canvasHeight);
    context.fillRect(0, 0, canvas.width, canvas.height);
  }

  measureTextWidth(text: string) {
    const metrics = this.context.measureText(text);
    return { width: metrics.actualBoundingBoxRight + metrics.actualBoundingBoxLeft, height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent };
  }

  drawTitleOnCanvas (title: string) {
    const context = this.context;
    const currentFillStyle = context.fillStyle;
    context.font = SECONDARY_FONT;
    const { width: titleTextWidth, height: titleTextHeight } = this.measureTextWidth(title);
    const maxTextWidth = this.canvasWidth - TEXT_OFFSET_X * 2;

    const textWidthDiff = Math.max(0, titleTextWidth - maxTextWidth);
    const emWidth = this.context.measureText('e').width;
    const numberOfLettersToSlice = Math.ceil(textWidthDiff / emWidth);
    let shortenedTitle = title.substring(0, title.length - numberOfLettersToSlice);

    const { width: newTitleWidth } = this.measureTextWidth(shortenedTitle);
    if (newTitleWidth > maxTextWidth) {
      this.drawTitleOnCanvas(shortenedTitle);
      return;
    }

    if (numberOfLettersToSlice) {
      shortenedTitle = `${shortenedTitle}...`;
    }

    const offsetY = TOP_MARGIN + titleTextHeight;

    // Center the text within the bounding space its in
    const diff = TITLE_TEXT_HEIGHT - titleTextHeight;
    const delta = diff / 4;

    const secondaryTextColor = this.getThemePropertyWithDarkMode('secondaryTextColor');
    context.fillStyle = CanvasImageGenerator.generateGradientOrColor(this.context, secondaryTextColor, titleTextWidth, titleTextHeight);
    context.fillText(shortenedTitle, TEXT_OFFSET_X, offsetY + delta);

    context.fillStyle = currentFillStyle;
    return offsetY;
  }

  drawAuthorOnCanvas() {
    const author = this.highlight.author || '';
    const context = this.context;
    context.font = SECONDARY_FONT;
    const metrics = context.measureText(author);
    const textWidth = metrics.width;
    const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

    const maxTextWidth = this.canvasWidth - TEXT_OFFSET_X * 2;

    const textWidthDiff = Math.max(0, textWidth - maxTextWidth);
    const emWidth = this.context.measureText('e').width;
    const numberOfLettersToSlice = Math.ceil(textWidthDiff / emWidth);
    let shortenedAuthor = author.substring(0, author.length - numberOfLettersToSlice);
    if (numberOfLettersToSlice) {
      shortenedAuthor = `${shortenedAuthor}...`;
    }

    const currentFillStyle = context.fillStyle;

    const secondaryTextColor = this.getThemePropertyWithDarkMode('secondaryTextColor');
    context.fillStyle = CanvasImageGenerator.generateGradientOrColor(this.context, secondaryTextColor, textWidth, textHeight);
    const offsetY = this.canvasHeight - BOTTOM_MARGIN;
    // Center the text within the bounding space its in
    const diff = AUTHOR_TEXT_HEIGHT - textHeight;
    const delta = diff / 2;
    context.fillText(shortenedAuthor, TEXT_OFFSET_X, offsetY - delta);

    context.fillStyle = currentFillStyle;
  }

  drawHighlightBlocksOnCanvas (allBlocks: HighlightBlock[], startY: number) {
    const context = this.context;

    const currentFillStyle = context.fillStyle;
    let currentTextPosX = TEXT_OFFSET_X;
    let currentLineCount = 0;

    for (const [blockIndex, block] of allBlocks.entries()) {
      if (block.lineNumber !== currentLineCount) {
        currentLineCount = block.lineNumber;
        currentTextPosX = TEXT_OFFSET_X;
      }
      if (block.style.bold) {
        context.font = this.getPrimaryFontStyle(this.primaryFontSize, { bold: true });
      } else if (block.style.italic) {
        context.font = this.getPrimaryFontStyle(this.primaryFontSize, { italic: true });
      } else {
        context.font = this.getPrimaryFontStyle(this.primaryFontSize);
      }
      let currentText = block.text;
      if (currentTextPosX === TEXT_OFFSET_X) {
        // trim any whitespace on new lines
        currentText = currentText.trimStart();
      }

      const highlightLength = context.measureText(currentText).width;
      const trimmedHighlightLength = context.measureText(currentText.trim()).width;
      const textHeight = this.getPrimaryTextHeightOnCanvas(this.primaryFontSize);

      const lineHeight = CanvasImageGenerator.computeLineHeight(this.primaryFontSize);
      const textStartY = startY + textHeight * block.lineNumber + textHeight;

      const textLineHeight = textHeight - textHeight / lineHeight;


      const textBackgroundColor = this.getThemePropertyWithDarkMode('textBackgroundColor');

      this.debugDrawBorder(currentTextPosX - HYPER_HIGHLIGHT_X_PADDING, textStartY - textHeight, highlightLength || 30, textLineHeight / 2, 'green');
      this.debugDrawBorder(currentTextPosX - HYPER_HIGHLIGHT_X_PADDING, textStartY - textLineHeight / 2, highlightLength || 30, textLineHeight / 2, 'green');
      this.debugDrawBorder(currentTextPosX - HYPER_HIGHLIGHT_X_PADDING, textStartY - textHeight, highlightLength || 30, textHeight, 'red');

      // If the current block is the last block of the line, trim the highlight background
      let highlightTextBackgroundLength = highlightLength;
      if (allBlocks[blockIndex + 1]) {
        highlightTextBackgroundLength = allBlocks[blockIndex + 1].lineNumber !== currentLineCount ? trimmedHighlightLength : highlightLength;
      }
      if (textBackgroundColor && trimmedHighlightLength > 0) {
        context.fillStyle = textBackgroundColor;
        context.fillRect(
          currentTextPosX - HYPER_HIGHLIGHT_X_PADDING,
          textStartY - textHeight + 2,
          highlightTextBackgroundLength + HYPER_HIGHLIGHT_X_PADDING * 2,
          textHeight - 4,
        );
      }
      context.fillStyle = currentFillStyle;
      context.fillText(currentText, currentTextPosX, textStartY - textLineHeight / 2);
      currentTextPosX += highlightLength;
    }
    context.fillStyle = currentFillStyle;
  }

  drawHighlight(highlightBlocks: HighlightBlock[]): Promise<void> {
    return new Promise((resolve) => {

      const highlightBlockLineCount = highlightBlocks[highlightBlocks.length - 1].lineNumber + 1;

      this.drawTitleOnCanvas(this.highlight.title || '');

      const fullHighlightSectionHeight = this.canvasHeight - this.titleSectionHeight - this.authorSectionHeight;
      const textHeightForLine = this.getPrimaryTextHeightOnCanvas(this.primaryFontSize);
      const totalTextHeight = textHeightForLine * highlightBlockLineCount;

      this.debugDrawBorder(TEXT_OFFSET_X, this.titleSectionHeight, this.maxTextWidth, fullHighlightSectionHeight);

      // Add this padding to the text start Y position to center the text within the highlight section
      const textHeightDiff = Math.max(0, fullHighlightSectionHeight - totalTextHeight);
      const padding = textHeightDiff / 2;


      this.debugDrawBorder(TEXT_OFFSET_X, this.titleSectionHeight + padding, this.maxTextWidth, totalTextHeight, 'blue');

      this.drawAuthorOnCanvas();

      this.drawHighlightBlocksOnCanvas(
        highlightBlocks,
        this.titleSectionHeight + padding,
      );

      resolve();
    });
  }

  renderHighlightOnCanvas(): Promise<string> {
    return new Promise((resolve) => {
      this.resetCanvas();
      this.primaryFontSize = this.findOptimalFontSize();
      const font = this.getPrimaryFontStyle(this.primaryFontSize);
      const allBlocks = scanAllHighlightTextBlocks(this.canvas, this.highlight.text, this.maxTextWidth, font);
      // Fill background
      this.fillBackgroundOnCanvas();
      this.setCanvasFillStyle();

      const context = this.context;

      const backgroundImageUrl = this.getThemePropertyWithDarkMode('backgroundImageUrl');
      if (backgroundImageUrl && typeof backgroundImageUrl === 'string') {
        const image = new Image();
        image.onload = () => {
          const { width: imageWidth, height: imageHeight } = image;
          let imageRatio = imageWidth / this.canvasWidth;
          if (this.canvas.height > this.canvas.width) {
            imageRatio = imageHeight / this.canvasHeight;
          }
          // Draw the loaded image, trying to position it centrally within the this.canvas
          context.drawImage(
            image,
            Math.min(0, (this.canvas.width - imageWidth) / 2),
            Math.min(0, (this.canvas.height - imageHeight) / 2),
            imageWidth / imageRatio,
            imageHeight / imageRatio,
          );
          this.drawHighlight(allBlocks).then(() => {
            resolve(this.canvas.toDataURL());
          }).catch((e) => logger.error('could not draw highlight', { e }));
          if (this.debugMode) {
            this.debugDrawBorders(this.canvasHeight);
          }
        };
        image.crossOrigin = 'Anonymous';
        image.src = backgroundImageUrl;
      } else {
        this.drawHighlight(allBlocks).then(() => {
          resolve(this.canvas.toDataURL());
        }).catch((e) => logger.error('could not draw highlight', { e }));
        if (this.debugMode) {
          this.debugDrawBorders(this.canvasHeight);
        }
      }
    });
  }


  debugDrawBorders(newHeight: number) {
    if (!this.debugMode) {
      return;
    }
    const context = this.context;
    // draw border around title section
    context.strokeStyle = 'red';
    context.beginPath();
    context.rect(0, 0, 540, this.titleSectionHeight);
    context.stroke();
    // draw individual margins for title
    // TOP MARGIN
    context.rect(0, 0, 540, TOP_MARGIN);
    context.stroke();
    context.rect(0, TOP_MARGIN, 540, TITLE_TEXT_HEIGHT);
    context.stroke();
    context.rect(0, TOP_MARGIN + TITLE_TEXT_HEIGHT, 540, this.titleBottomMargin);
    context.stroke();
    context.closePath();

    context.beginPath();
    context.strokeStyle = 'green';
    // draw border around author section
    context.rect(0, newHeight - this.authorSectionHeight, 540, this.authorSectionHeight);
    context.stroke();

    // draw individual margins for author
    context.rect(0, newHeight - this.authorSectionHeight, 540, this.authorTopMargin);
    // context.stroke();
    context.rect(0, newHeight - this.authorSectionHeight + this.authorTopMargin, 540, AUTHOR_TEXT_HEIGHT);
    context.stroke();
    context.rect(0, newHeight - this.authorSectionHeight + this.authorTopMargin + AUTHOR_TEXT_HEIGHT, 540, BOTTOM_MARGIN);
    context.stroke();
    context.closePath();
  }

  debugDrawBorder (startX: number, startY: number, width: number, height: number, color = 'orange') {
    if (!this.debugMode) {
      return;
    }
    const context = this.context;
    context.strokeStyle = color;
    context.beginPath();
    context.rect(startX, startY, width, height);
    context.stroke();
    context.closePath();
  }

}

