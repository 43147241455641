import {
  ClassicColor,
  ClassicThemeVariantId, FreshVariantId,
  GradientVariantId, ModernVariantId, QuoteshotFont,
  QuoteshotTheme,
  QuoteshotThemeName, ScribbleVariantId,
  ThemeVariant, UnstyledVariantId,
} from '../types/quoteshots';
import {
  backgroundImageUrls,
  darkBackgroundImageUrls,
  darkPreviewImageUrls,
  previewImageUrls,
} from './quoteshotImageAssets.platform';

const SOLID_BLACK_COLOR = '#000000';

const SOLID_BLACK_SURFACE_COLOR = '#10161D';

const SOLID_DARK_MODE_GREY_COLOR = '#F0F1F2';

const SOLID_PURPLE_COLOR = '#1F0C41';
const SOLID_PURPLE_COLOR_70ALPHA = 'rgba(31,12,65,0.7)';

const CLASSIC_DARK_MODE_BACKGROUND = '#000000';

const SOLID_WHITE_COLOR = '#ffffff';
const SOLID_WHITE_COLOR_70ALPHA = 'rgba(255,255,255,0.7)';

const TEXT_PRIMARY_COLOR = '#28313B';
const TEXT_PRIMARY_COLOR_DARK_MODE = '#F0F1F2';
const TEXT_SECONDARY_COLOR = '#616C77';
const TEXT_SECONDARY_COLOR_DARK_MODE = '#A3ACB7';

const FRESH_GREY_COLOR = 'rgba(66,79,82,0.7)';
const FRESH_WHITE_COLOR = 'rgba(255,255,255,0.7)';

export const ClassicThemeVariants: ThemeVariant[] = [
  {
    id: ClassicThemeVariantId.Yellow,
    textColor: SOLID_BLACK_COLOR,
    textBackgroundColor: ClassicColor.Yellow,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    previewColor: 'rgb(255,235,194)',
    darkTextColor: {
      start: { offset: 0, color: '#FAF0BF' },
      end: { offset: 1, color: '#FAF0BF' },
    },
    darkTextBackgroundColor: '#313019',
    darkBackgroundColor: CLASSIC_DARK_MODE_BACKGROUND,
    darkSecondaryTextColor: SOLID_DARK_MODE_GREY_COLOR,
  },
  {
    id: ClassicThemeVariantId.Purple,
    textColor: SOLID_BLACK_COLOR,
    textBackgroundColor: ClassicColor.Purple,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    previewColor: ClassicColor.Purple,
    darkTextColor: {
      start: { offset: 0, color: '#ECDDF8' },
      end: { offset: 1, color: '#ECDDF8' },
    },
    darkTextBackgroundColor: '#322845',
    darkBackgroundColor: CLASSIC_DARK_MODE_BACKGROUND,
    darkSecondaryTextColor: SOLID_DARK_MODE_GREY_COLOR,
  },
  {
    id: ClassicThemeVariantId.Blue,
    textColor: SOLID_BLACK_COLOR,
    textBackgroundColor: ClassicColor.Blue,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    previewColor: ClassicColor.Blue,
    darkTextColor: {
      start: { offset: 0, color: '#CFE7FD' },
      end: { offset: 1, color: '#CFE7FD' },
    },
    darkTextBackgroundColor: '#1D2F47',
    darkBackgroundColor: CLASSIC_DARK_MODE_BACKGROUND,
    darkSecondaryTextColor: SOLID_DARK_MODE_GREY_COLOR,
  },
  {
    id: ClassicThemeVariantId.Red,
    textColor: SOLID_BLACK_COLOR,
    textBackgroundColor: ClassicColor.Red,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    previewColor: ClassicColor.Red,
    darkTextColor: {
      start: { offset: 0, color: '#FADBDA' },
      end: { offset: 1, color: '#FADBDA' },
    },
    darkTextBackgroundColor: '#3D2529',
    darkBackgroundColor: CLASSIC_DARK_MODE_BACKGROUND,
    darkSecondaryTextColor: SOLID_DARK_MODE_GREY_COLOR,
  },
  {
    id: ClassicThemeVariantId.Green,
    textColor: SOLID_BLACK_COLOR,
    textBackgroundColor: ClassicColor.Green,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    previewColor: ClassicColor.Green,
    darkTextColor: {
      start: { offset: 0, color: '#CCF4E6' },
      end: { offset: 1, color: '#CCF4E6' },
    },
    darkTextBackgroundColor: '#163231',
    darkBackgroundColor: CLASSIC_DARK_MODE_BACKGROUND,
    darkSecondaryTextColor: SOLID_DARK_MODE_GREY_COLOR,
  },
];

export const FreshThemeVariants: ThemeVariant[] = [
  {
    id: FreshVariantId.BlueRed,
    textColor: '#D7310F',
    backgroundColor: '#CBD6D9',
    secondaryTextColor: FRESH_GREY_COLOR,
    previewColor: '#CBD6D9',
    previewImageUrl: previewImageUrls[FreshVariantId.BlueRed],
  },
  {
    id: FreshVariantId.RedBlue,
    textColor: '#CBD6D9',
    backgroundColor: '#D7310F',
    secondaryTextColor: FRESH_WHITE_COLOR,
    previewColor: '#D7310F',
    previewImageUrl: previewImageUrls[FreshVariantId.RedBlue],
  },
  {
    id: FreshVariantId.GreyGreen,
    textColor: '#766C08',
    backgroundColor: '#E7E7E7',
    secondaryTextColor: FRESH_GREY_COLOR,
    previewColor: '#E7E7E7',
    previewImageUrl: previewImageUrls[FreshVariantId.GreyGreen],
  },
  {
    id: FreshVariantId.GreenGrey,
    textColor: '#E7E7E7',
    backgroundColor: '#766C08',
    secondaryTextColor: FRESH_WHITE_COLOR,
    previewColor: '#766C08',
    previewImageUrl: previewImageUrls[FreshVariantId.GreenGrey],
  },
  {
    id: FreshVariantId.BeigePurple,
    textColor: '#362B57',
    backgroundColor: '#E1DFD5',
    secondaryTextColor: FRESH_GREY_COLOR,
    previewColor: '#E1DFD5',
    previewImageUrl: previewImageUrls[FreshVariantId.BeigePurple],
  },
  {
    id: FreshVariantId.PurpleBeige,
    textColor: '#E1DFD5',
    backgroundColor: '#362B57',
    secondaryTextColor: FRESH_WHITE_COLOR,
    previewColor: '#362B57',
    previewImageUrl: previewImageUrls[FreshVariantId.PurpleBeige],
  },
  {
    id: FreshVariantId.TealGreen,
    textColor: '#006342',
    backgroundColor: '#A8DEEA',
    secondaryTextColor: FRESH_GREY_COLOR,
    previewColor: '#A8DEEA',
    previewImageUrl: previewImageUrls[FreshVariantId.TealGreen],
  },
  {
    id: FreshVariantId.GreenTeal,
    textColor: '#A8DEEA',
    backgroundColor: '#006342',
    secondaryTextColor: FRESH_WHITE_COLOR,
    previewColor: '#006342',
    previewImageUrl: previewImageUrls[FreshVariantId.GreenTeal],
  },
];

const ModernThemeVariants: ThemeVariant[] = [
  {
    id: ModernVariantId.OrangePink,
    textColor: {
      angle: 45,
      start: { offset: 0, color: '#EC942B' },
      end: { offset: 1, color: '#A60FCC' },
    },
    darkTextColor: {
      angle: 45,
      start: { offset: 0, color: '#EFAE73' },
      end: { offset: 1, color: '#C158DC' },
    },
    backgroundColor: SOLID_WHITE_COLOR,
    darkBackgroundColor: SOLID_BLACK_SURFACE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    darkSecondaryTextColor: TEXT_SECONDARY_COLOR_DARK_MODE,
    previewColor: '#FFFFFF',
    previewImageUrl: previewImageUrls[ModernVariantId.OrangePink],
    darkPreviewImageUrl: darkPreviewImageUrls[ModernVariantId.OrangePink],
  },
  {
    id: ModernVariantId.BlueRed,
    textColor: {
      angle: 45,
      start: { offset: 0, color: '#729BFE' },
      end: { offset: 1, color: '#F2353C' },
    },
    darkTextColor: {
      angle: 45,
      start: { offset: 0, color: '#A6C0FE' },
      end: { offset: 1, color: '#F68084' },
    },
    backgroundColor: SOLID_WHITE_COLOR,
    darkBackgroundColor: SOLID_BLACK_SURFACE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    darkSecondaryTextColor: TEXT_SECONDARY_COLOR_DARK_MODE,
    previewColor: '#FFFFFF',
    previewImageUrl: previewImageUrls[ModernVariantId.BlueRed],
    darkPreviewImageUrl: darkPreviewImageUrls[ModernVariantId.BlueRed],
  },
  {
    id: ModernVariantId.TealBlue,
    textColor: {
      angle: 45,
      start: { offset: 0, color: '#7AC8BB' },
      end: { offset: 1, color: '#5C50D3' },
    },
    darkTextColor: {
      angle: 45,
      start: { offset: 0, color: '#B1F4CF' },
      end: { offset: 1, color: '#9890E3' },
    },
    backgroundColor: SOLID_WHITE_COLOR,
    darkBackgroundColor: SOLID_BLACK_SURFACE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    darkSecondaryTextColor: TEXT_SECONDARY_COLOR_DARK_MODE,
    previewColor: '#FFFFFF',
    previewImageUrl: previewImageUrls[ModernVariantId.TealBlue],
    darkPreviewImageUrl: darkPreviewImageUrls[ModernVariantId.TealBlue],
  },
  {
    id: ModernVariantId.OrangePurple,
    textColor: {
      angle: 45,
      start: { offset: 0, color: '#D87951' },
      end: { offset: 1, color: '#A090F2' },
    },
    darkTextColor: {
      angle: 45,
      start: { offset: 0, color: '#EBBBA7' },
      end: { offset: 1, color: '#CFC7F8' },
    },
    backgroundColor: SOLID_WHITE_COLOR,
    darkBackgroundColor: SOLID_BLACK_SURFACE_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    darkSecondaryTextColor: TEXT_SECONDARY_COLOR_DARK_MODE,
    previewColor: '#FFFFFF',
    previewImageUrl: previewImageUrls[ModernVariantId.OrangePurple],
    darkPreviewImageUrl: darkPreviewImageUrls[ModernVariantId.OrangePurple],
  },
];

const UnstyledThemeVariants: ThemeVariant[] = [
  {
    id: UnstyledVariantId.White,
    textColor: SOLID_BLACK_COLOR,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: SOLID_BLACK_COLOR,
    previewColor: '#FFFFFF',
    fontOverrides: {
      [QuoteshotFont.Serif]: 'Times New Roman',
      [QuoteshotFont.SansSerif]: 'Helvetica',
    },
  },
  {
    id: UnstyledVariantId.Black,
    textColor: SOLID_WHITE_COLOR,
    backgroundColor: SOLID_BLACK_COLOR,
    secondaryTextColor: SOLID_WHITE_COLOR,
    previewColor: '#000000',
    fontOverrides: {
      [QuoteshotFont.Serif]: 'Times New Roman',
      [QuoteshotFont.SansSerif]: 'Helvetica',
    },
  },
];

const ScribbleThemeVariants: ThemeVariant[] = [
  {
    id: ScribbleVariantId.Brush,
    textColor: TEXT_PRIMARY_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    darkTextColor: TEXT_PRIMARY_COLOR_DARK_MODE,
    darkSecondaryTextColor: TEXT_SECONDARY_COLOR_DARK_MODE,
    backgroundColor: SOLID_WHITE_COLOR,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[ScribbleVariantId.Brush],
    previewImageUrl: previewImageUrls[ScribbleVariantId.Brush],
    darkBackgroundImageUrl: darkBackgroundImageUrls[ScribbleVariantId.Brush],
    darkPreviewImageUrl: darkPreviewImageUrls[ScribbleVariantId.Brush],
  },
  {
    id: ScribbleVariantId.Circles,
    textColor: TEXT_PRIMARY_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    darkTextColor: TEXT_PRIMARY_COLOR_DARK_MODE,
    darkSecondaryTextColor: TEXT_SECONDARY_COLOR_DARK_MODE,
    backgroundColor: SOLID_WHITE_COLOR,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[ScribbleVariantId.Circles],
    previewImageUrl: previewImageUrls[ScribbleVariantId.Circles],
    darkBackgroundImageUrl: darkBackgroundImageUrls[ScribbleVariantId.Circles],
    darkPreviewImageUrl: darkPreviewImageUrls[ScribbleVariantId.Circles],
  },
  {
    id: ScribbleVariantId.Arrows,
    textColor: TEXT_PRIMARY_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    darkTextColor: TEXT_PRIMARY_COLOR_DARK_MODE,
    darkSecondaryTextColor: TEXT_SECONDARY_COLOR_DARK_MODE,
    backgroundColor: SOLID_WHITE_COLOR,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[ScribbleVariantId.Arrows],
    previewImageUrl: previewImageUrls[ScribbleVariantId.Arrows],
    darkBackgroundImageUrl: darkBackgroundImageUrls[ScribbleVariantId.Arrows],
    darkPreviewImageUrl: darkPreviewImageUrls[ScribbleVariantId.Arrows],
  },
  {
    id: ScribbleVariantId.Waves,
    textColor: TEXT_PRIMARY_COLOR,
    secondaryTextColor: TEXT_SECONDARY_COLOR,
    darkTextColor: TEXT_PRIMARY_COLOR_DARK_MODE,
    darkSecondaryTextColor: TEXT_SECONDARY_COLOR_DARK_MODE,
    backgroundColor: SOLID_WHITE_COLOR,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[ScribbleVariantId.Waves],
    previewImageUrl: previewImageUrls[ScribbleVariantId.Waves],
    darkBackgroundImageUrl: darkBackgroundImageUrls[ScribbleVariantId.Waves],
    darkPreviewImageUrl: darkPreviewImageUrls[ScribbleVariantId.Waves],
  },
];

const GradientThemeVariants: ThemeVariant[] = [
  {
    id: GradientVariantId.Pink,
    textColor: SOLID_PURPLE_COLOR,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: SOLID_PURPLE_COLOR_70ALPHA,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[GradientVariantId.Pink],
    previewImageUrl: previewImageUrls[GradientVariantId.Pink],
  },
  {
    id: GradientVariantId.Blue,
    textColor: SOLID_PURPLE_COLOR,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: SOLID_PURPLE_COLOR_70ALPHA,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[GradientVariantId.Blue],
    previewImageUrl: previewImageUrls[GradientVariantId.Blue],
  },
  {
    id: GradientVariantId.Yellow,
    textColor: SOLID_PURPLE_COLOR,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: SOLID_PURPLE_COLOR_70ALPHA,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[GradientVariantId.Yellow],
    previewImageUrl: previewImageUrls[GradientVariantId.Yellow],
  },
  {
    id: GradientVariantId.Peach,
    textColor: SOLID_PURPLE_COLOR,
    backgroundColor: SOLID_WHITE_COLOR,
    secondaryTextColor: SOLID_PURPLE_COLOR_70ALPHA,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[GradientVariantId.Peach],
    previewImageUrl: previewImageUrls[GradientVariantId.Peach],
  },
  {
    id: GradientVariantId.BlueDark,
    textColor: SOLID_WHITE_COLOR,
    backgroundColor: SOLID_BLACK_COLOR,
    secondaryTextColor: SOLID_WHITE_COLOR_70ALPHA,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[GradientVariantId.BlueDark],
    previewImageUrl: previewImageUrls[GradientVariantId.BlueDark],
  },
  {
    id: GradientVariantId.OrangeDark,
    textColor: SOLID_WHITE_COLOR,
    backgroundColor: SOLID_BLACK_COLOR,
    secondaryTextColor: SOLID_WHITE_COLOR_70ALPHA,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[GradientVariantId.OrangeDark],
    previewImageUrl: previewImageUrls[GradientVariantId.OrangeDark],
  },
  {
    id: GradientVariantId.PurpleDark,
    textColor: SOLID_WHITE_COLOR,
    backgroundColor: SOLID_BLACK_COLOR,
    secondaryTextColor: SOLID_WHITE_COLOR_70ALPHA,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[GradientVariantId.PurpleDark],
    previewImageUrl: previewImageUrls[GradientVariantId.PurpleDark],
  },
  {
    id: GradientVariantId.RedDark,
    textColor: SOLID_WHITE_COLOR,
    backgroundColor: SOLID_BLACK_COLOR,
    secondaryTextColor: SOLID_WHITE_COLOR_70ALPHA,
    previewColor: '#FFFFFF',
    backgroundImageUrl: backgroundImageUrls[GradientVariantId.RedDark],
    previewImageUrl: previewImageUrls[GradientVariantId.RedDark],
  },
];
export const QuoteshotThemes: QuoteshotTheme[] = [
  {
    id: QuoteshotThemeName.Classic,
    hasDarkMode: true,
    variants: ClassicThemeVariants,
  },
  {
    id: QuoteshotThemeName.Scribble,
    hasDarkMode: true,
    variants: ScribbleThemeVariants,
  },
  {
    id: QuoteshotThemeName.Fresh,
    variants: FreshThemeVariants,
  },
  {
    id: QuoteshotThemeName.Modern,
    hasDarkMode: true,
    variants: ModernThemeVariants,
  },
  {
    id: QuoteshotThemeName.Gradient,
    variants: GradientThemeVariants,
  },
  {
    id: QuoteshotThemeName.Unstyled,
    variants: UnstyledThemeVariants,
  },
];

