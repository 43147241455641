import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { useCallback, useEffect, useMemo } from 'react';

import { QuoteshotThemes } from '../../quoteshots/themes';
import { QuoteshotAspectRatio, QuoteshotFont, QuoteshotTheme, ThemeVariant } from '../../types/quoteshots';
import { globalState } from '../models';
import {
  setCurrentQuoteshotFont,
  setQuoteshotDarkMode,
  updateCurrentQuoteshotAspectRatio,
  updateCurrentQuoteshotTheme,
  updateCurrentQuoteshotVariant,
} from '../stateUpdaters/clientStateUpdaters/quoteshot';


export const useQuoteshotState = () => {
  const currentQuoteShotThemeName = globalState((state) => state.client.quoteshot.currentThemeName);
  const aspectRatio = globalState((state) => state.client.quoteshot.aspectRatio);
  const isDarkMode = globalState((state) => state.client.quoteshot.isDarkMode);
  const currentFont = globalState((state) => state.client.quoteshot.currentFont);
  const selectedVariantForThemeMap = globalState((state) => state.client.quoteshot.variantForTheme);

  const selectedVariantId = useMemo(() => {
    return selectedVariantForThemeMap[currentQuoteShotThemeName];
  }, [currentQuoteShotThemeName, selectedVariantForThemeMap]);

  const currentThemeVariants = useMemo(() => {
    return find(QuoteshotThemes, { id: currentQuoteShotThemeName })?.variants ?? QuoteshotThemes[0].variants;
  }, [currentQuoteShotThemeName]);

  const currentThemeVariantIndex = useMemo(() => {
    return findIndex(currentThemeVariants, { id: selectedVariantId });
  }, [currentThemeVariants, selectedVariantId]);

  return {
    currentQuoteShotThemeName,
    aspectRatio,
    isDarkMode,
    currentFont,
    selectedVariantForThemeMap,
    selectedVariantId,
    currentThemeVariants,
    currentThemeVariantIndex,
  };

};
export const useQuoteshotActions = ({
  currentQuoteShotThemeName,
}: {
  currentQuoteShotThemeName: QuoteshotTheme['id'];
}) => {

  const currentQuoteshotIndex = useMemo(() => {
    return findIndex(QuoteshotThemes, { id: currentQuoteShotThemeName });
  }, [currentQuoteShotThemeName]);

  const currentQuoteshotTheme = useMemo(() => {
    return find(QuoteshotThemes, { id: currentQuoteShotThemeName });
  }, [currentQuoteShotThemeName]);

  const selectVariant = useCallback((variantId: ThemeVariant['id']) => {
    updateCurrentQuoteshotVariant(currentQuoteShotThemeName, variantId);
  }, [currentQuoteShotThemeName]);

  const findVariantInThemeById = useCallback((themeVariants: ThemeVariant[], variantId: string): ThemeVariant => {
    const foundThemeVariant = find(themeVariants, { id: variantId }) as ThemeVariant | undefined;
    if (!foundThemeVariant) {
      return themeVariants[0];
    }
    return foundThemeVariant;
  }, []);

  const updateRatio = useCallback((ratio: QuoteshotAspectRatio) => {
    updateCurrentQuoteshotAspectRatio(ratio);
  }, []);

  const setDarkMode = useCallback(() => {
    setQuoteshotDarkMode(true);
  }, []);

  const setLightMode = useCallback(() => {
    setQuoteshotDarkMode(false);
  }, []);

  const setSerif = useCallback(() => {
    setCurrentQuoteshotFont(QuoteshotFont.Serif);
  }, []);

  const setSansSerif = useCallback(() => {
    setCurrentQuoteshotFont(QuoteshotFont.SansSerif);
  }, []);


  useEffect(() => {
    if (currentQuoteshotIndex < 0) {
      // This means we somehow have selected a current Quoteshot Theme that does not exist, update the theme to default
      updateCurrentQuoteshotTheme(QuoteshotThemes[0].id);
    }
  }, [currentQuoteshotIndex]);

  return {
    currentQuoteshotIndex,
    currentQuoteshotTheme,
    selectVariant,
    findVariantInThemeById,
    setDarkMode,
    setLightMode,
    setSansSerif,
    setSerif,
    updateRatio,
  };
};
