import React from 'react';

import Icon from './Icon';

export default function StrokeArrowDownIcon({ className = '', text = 'ArrowDown' }: {className?: string; text?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg width="20" className={className} height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4.01758C10.4142 4.01758 10.75 4.35336 10.75 4.76758L10.75 14.9569L13.9697 11.7372C14.2626 11.4444 14.7374 11.4444 15.0303 11.7372C15.3232 12.0301 15.3232 12.505 15.0303 12.7979L10 17.8282L4.96967 12.7979C4.67678 12.505 4.67678 12.0301 4.96967 11.7372C5.26256 11.4444 5.73744 11.4444 6.03033 11.7372L9.25 14.9569L9.25 4.76758C9.25 4.35336 9.58579 4.01758 10 4.01758Z" fill="black" />
    </svg>

  </Icon>;
}
