import React, { useEffect, useRef, useState } from 'react';

import { CanvasImageGenerator, CanvasImageHighlight } from '../../../../shared/quoteshots/CanvasImageGenerator';
import { QuoteshotFont, ThemeVariant } from '../../../../shared/types/quoteshots';
import delay from '../../../../shared/utils/delay';
import makeLogger from '../../../../shared/utils/makeLogger';
import styles from '../QuoteshotModal.module.css';
import Spinner from '../Spinner';

const logger = makeLogger(__filename);

interface HighlightCanvasProps {
  highlight: CanvasImageHighlight;
  setCreatedImgSrc: (src: string) => void;
  setCreatedImgBlob: (blob: Blob) => void;
  aspectRatio: number;
  width: number;
  isActive: boolean;
  currentFont: QuoteshotFont;
  theme: ThemeVariant;
  darkMode: boolean;
}
export const HighlightCanvas = ({
  highlight, setCreatedImgSrc, aspectRatio, width, theme, isActive, darkMode, setCreatedImgBlob, currentFont,
}: HighlightCanvasProps) => {

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [initialRenderDone, setInitialRenderDone] = useState(false);
  const [internalImageSrc, setInternalImageSrc] = useState<string>();
  const [internalImageBlob, setInternalImageBlob] = useState<Blob>();
  const [canvasLoading, setCanvasLoading] = useState(false);

  // These two props help us understand if we need to rerender the highlight while it was inactive
  const [didPropsChangeWhileInactive, setDidPropsChangeWhileInactive] = useState(false);
  const [needsRerender, setNeedsRerender] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setDidPropsChangeWhileInactive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRenderDone, aspectRatio, width, highlight.text, highlight.author, highlight.title, theme, darkMode, currentFont]);

  useEffect(() => {
    // If this quoteshot was inactive but props changed, we need to rerender it, otherwise the render function wont be called when
    // this quoteshot becomes active
    if (isActive && didPropsChangeWhileInactive) {
      setNeedsRerender(true);
    }
  }, [didPropsChangeWhileInactive, isActive]);


  useEffect(() => {
    if (!isActive || !internalImageSrc) {
      return;
    }
    setCreatedImgSrc(internalImageSrc);
    if (internalImageBlob) {
      setCreatedImgBlob(internalImageBlob);
    }
  }, [internalImageBlob, internalImageSrc, isActive, setCreatedImgBlob, setCreatedImgSrc]);


  const isActiveRef = useRef<boolean>(isActive);

  useEffect(() => {
    isActiveRef.current = isActive;
  }, [isActive]);

  const isLoading = useRef(false);
  useEffect(() => {
    if (!canvasRef.current || !highlight.text || initialRenderDone && !isActiveRef.current || isLoading.current) {
      return;
    }
    setNeedsRerender(false);
    setDidPropsChangeWhileInactive(false);
    setCanvasLoading(true);
    isLoading.current = true;
    const asyncRender = async () => {
      if (!canvasRef.current) {
        return;
      }
      const highlightGenerator = new CanvasImageGenerator(
        canvasRef.current, theme, highlight, width, aspectRatio, darkMode, currentFont,
      );
      if (!initialRenderDone && !isActiveRef.current || needsRerender) {
        // Add a delay because we just became active and probably are scrolling the parent carousel
        await delay(400);
      } else if (!initialRenderDone && isActiveRef) {
        await delay(200);
      }
      try {
        const imgSrc = await highlightGenerator.renderHighlightOnCanvas();
        setInternalImageSrc(imgSrc);
        setInitialRenderDone(true);
        setCanvasLoading(false);
        isLoading.current = false;
        highlightGenerator.canvas.toBlob((blob) => {
          if (!blob) {
            return;
          }
          setInternalImageBlob(blob);
        });
      } catch (e) {
        logger.error('Render highlight error ', { e });
      }
    };
    asyncRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRenderDone, needsRerender, aspectRatio, width, highlight.text, highlight.author, highlight.title, theme, darkMode, currentFont]);


  return (
    <>
      <canvas
        aria-label="quoteshot-canvas"
        className={styles.canvas}
        ref={canvasRef}
      />
      {/* This div preloads the font family, its needed for the canvas to render the right font */}
      <div style={{ fontFamily: 'Public Sans VF', opacity: 0, position: 'absolute', zIndex: -10000 }}>
        canvas
      </div>
      {canvasLoading && isActive && <Spinner className={styles.canvasImageLoadingSpinner} />}
      <img alt="quoteshot" src={internalImageSrc} className={`${styles.canvasImage} ${canvasLoading && isActive ? styles.canvasImageLoading : ''}`} />
    </>
  );
};
