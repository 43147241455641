import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { globalState } from '../../../shared/foreground/models';
import { useDocument } from '../../../shared/foreground/stateHooks';
import { useQuoteshotActions, useQuoteshotState } from '../../../shared/foreground/stateHooks/quoteshotHooks';
import { setQuoteshotModalOpen, updateCurrentQuoteshotTheme } from '../../../shared/foreground/stateUpdaters/clientStateUpdaters/quoteshot';
import { createToast } from '../../../shared/foreground/toasts.platform';
import { getThemePropertyWithDarkMode } from '../../../shared/quoteshots/CanvasImageGenerator';
import { QuoteshotThemes } from '../../../shared/quoteshots/themes';
import { FirstClassDocument, Highlight } from '../../../shared/types';
import { QuoteshotAspectRatio, QuoteshotFont } from '../../../shared/types/quoteshots';
import getDocumentOverrideOrReal from '../../../shared/utils/getDocumentOverrideOrReal';
import getServerBaseUrl from '../../../shared/utils/getServerBaseUrl.platform';
import requestWithAuth from '../../../shared/utils/requestWithAuth.platformIncludingExtension';
import { openURL } from '../utils/openURL';
import { Dropdown, DropdownOption, DropdownOptionType } from './Dropdown/Dropdown';
import ChevronDown from './icons/20StrokeChevronDownSmall';
import DarkThemeIcon from './icons/DarkThemeIcon';
import LightThemeIcon from './icons/LightThemeIcon';
import QuoteshotArrowRight from './icons/quoteshotArrowRight';
import ShareIcon from './icons/ShareIcon';
import StrokeArrowDownIcon from './icons/StrokeArrowDownIcon';
import styles from './QuoteshotModal.module.css';
import { HighlightCanvas } from './QuoteshotModal/HighlightCanvas';

export function openTwitterShareScreen(highlightId: string, shareId: string) {
  const openUrl = new URL(`${getServerBaseUrl()}/tweet`);
  openUrl.searchParams.set('readerDocumentId', highlightId);
  openUrl.searchParams.set('shareId', shareId);
  openURL(openUrl.toString(), undefined, 'height=678,width=845,status=yes,toolbar=no,menubar=no,location=no');
}

const createShare = async (highlightId: string, image: string) => {
  const payload = {
    reader_document_id: highlightId,
    image: '',
  };
  if (image) {
    payload.image = image;
  }
  const resp = await requestWithAuth(`${getServerBaseUrl()}/api/v2/highlight_shares`,
    {
      body: JSON.stringify(payload),
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { 'Content-Type': 'application/json' },
    });
  const json = await resp.json();
  return json;
};
const ShareDropdown = ({ highlightId, imageBlob, imageSrc }: {imageBlob: Blob | undefined; highlightId: string; imageSrc: string;}) => {
  const [isOpen, setIsOpen] = useState(false);
  const trigger = useMemo(() => {
    return <DropdownMenu.Trigger asChild>
      <button aria-label="share-dropdown-button" type="button" className={`${styles.saveButton} ${styles.shareButton}`}>
        <ShareIcon className={styles.saveButtonArrow} />
        Share
        <ChevronDown />
      </button>
    </DropdownMenu.Trigger>;
  }, []);

  const saveImageToClipboard = useCallback(async () => {
    if (!imageBlob) {
      createToast({ content: 'Image could not be saved', category: 'error' });
      return;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const clipboardItem = new ClipboardItem({ 'image/png': imageBlob });
    await navigator.clipboard.write([clipboardItem]);
    createToast({ content: 'Image copied to clipboard', category: 'success' });
  }, [imageBlob]);

  const twitterShare = useCallback(async () => {
    const { id: shareId } = await createShare(highlightId, imageSrc);
    openTwitterShareScreen(highlightId, shareId);
  }, [highlightId, imageSrc]);

  const options = useMemo((): DropdownOption[] => {
    return [
      {
        name: 'Copy image to clipboard',
        onSelect: saveImageToClipboard,
        isDisabled: !imageBlob,
        type: DropdownOptionType.Item,
        className: styles.dropdownItem,
      },
      {
        name: 'Share to Twitter',
        onSelect: twitterShare,
        isDisabled: !imageBlob,
        type: DropdownOptionType.Item,
        className: styles.dropdownItem,
      },
    ];
  }, [imageBlob, saveImageToClipboard, twitterShare]);

  return <>
    <Dropdown
      contentAlignment="start"
      contentClassName={styles.dropdownContent}
      defaultTriggerAttributes={{
        tabIndex: -1,
      }}
      isOpen={isOpen}
      onEscapeKeyDown={() => setIsOpen(false)}
      options={options}
      setIsOpen={setIsOpen}
      trigger={trigger}
      triggerClassName={`${styles.saveButton} ${styles.shareButton}`}
      triggerTooltipText="Share image"
    />
  </>;
};
export default function QuoteshotModal() {
  const isOpen = globalState(useCallback((state) => state.quoteshotModalOpen, []));
  const highlightId = globalState((state) => state.quoteshotHighlightId);
  const [selectedHighlight] = useDocument<Highlight>(highlightId);

  const [parentDocument] = useDocument<FirstClassDocument>(selectedHighlight?.parent);
  const highlightHtml = useMemo(() => {
    if (!selectedHighlight) {
      return undefined;
    }
    return `${selectedHighlight.markdown || ''}`;
  }, [selectedHighlight]);

  const highlightDataForQuoteshot = useMemo(() => {
    if (!selectedHighlight || !parentDocument || !highlightId) {
      return undefined;
    }
    return {
      id: highlightId,
      author: getDocumentOverrideOrReal(parentDocument, 'author') ?? parentDocument.author,
      title: getDocumentOverrideOrReal(parentDocument, 'title') ?? parentDocument.title,
      text: highlightHtml || '',
      cover_image_url: parentDocument.image_url,
    };
  }, [selectedHighlight, parentDocument, highlightId, highlightHtml]);

  const [createdImgSrc, setCreatedImgSrc] = useState<string>('');
  const [createdImgBlob, setCreatedImgBlob] = useState<Blob>();

  const {
    currentFont,
    currentThemeVariants,
    currentQuoteShotThemeName,
    isDarkMode,
    aspectRatio,
    selectedVariantForThemeMap,
    selectedVariantId,
  } = useQuoteshotState();

  const {
    currentQuoteshotIndex,
    currentQuoteshotTheme,
    findVariantInThemeById,
    selectVariant,
    setDarkMode,
    setLightMode,
    setSansSerif,
    setSerif,
    updateRatio,
  } = useQuoteshotActions({ currentQuoteShotThemeName });


  const carouselRef = useRef<HTMLDivElement>(null);

  const goToPrevious = useCallback(() => {
    if (currentQuoteshotIndex < 1) {
      return;
    }
    updateCurrentQuoteshotTheme(QuoteshotThemes[currentQuoteshotIndex - 1].id);
  }, [currentQuoteshotIndex]);

  const goToNext = useCallback(() => {
    if (currentQuoteshotIndex >= QuoteshotThemes.length) {
      return;
    }
    updateCurrentQuoteshotTheme(QuoteshotThemes[currentQuoteshotIndex + 1].id);
  }, [currentQuoteshotIndex]);

  useEffect(() => {
    if (!carouselRef.current || !selectedHighlight || !highlightDataForQuoteshot) {
      return;
    }
    carouselRef.current.scrollTo({ left: (currentQuoteshotIndex + 1) * 524, behavior: 'smooth' });
  }, [currentQuoteshotIndex, isOpen, selectedHighlight, highlightDataForQuoteshot]);


  if (!selectedHighlight || !highlightDataForQuoteshot || !isOpen) {
    return null;
  }

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  return <div
    className={styles.modalWrapper}
    onClick={() => setQuoteshotModalOpen(false)}
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
      <div className={styles.modalTitle}>{currentQuoteshotTheme?.id}</div>
      <div className={styles.carouselContainer}>
        <button type="button" aria-label="previous-button" className={styles.carouselButton} style={{ left: 0 }} onClick={goToPrevious}><QuoteshotArrowRight className={styles.quoteshotArrowLeft} /></button>
        <div className={styles.carousel} ref={carouselRef}>
          <div key="carousel-start-item" className={`${styles.carouselItem}`} />
          {QuoteshotThemes.map((theme) =>
            <div key={theme.id} className={`${styles.carouselItem} ${currentQuoteShotThemeName === theme.id ? styles.active : ''}`}>
              <HighlightCanvas
                highlight={highlightDataForQuoteshot}
                isActive={currentQuoteShotThemeName === theme.id}
                setCreatedImgSrc={setCreatedImgSrc}
                setCreatedImgBlob={setCreatedImgBlob}
                aspectRatio={aspectRatio}
                width={504}
                darkMode={isDarkMode}
                currentFont={currentFont}
                theme={findVariantInThemeById(theme.variants, selectedVariantForThemeMap[theme.id])}
              />
            </div>)}
          <div key="carousel-end-item" className={`${styles.carouselItem}`} />
        </div>
        <button type="button" aria-label="next-button" className={styles.carouselButton} style={{ right: 0 }} onClick={goToNext}> <QuoteshotArrowRight /> </button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20, gap: 8 }}>
        <a
          href={createdImgSrc}
          download={`${highlightDataForQuoteshot.title}.png`}
        >
          <button type="button" aria-label="save-image" className={styles.saveButton}>
            <StrokeArrowDownIcon className={styles.saveButtonArrow} />
            Save image
          </button>
        </a>
        <ShareDropdown highlightId={selectedHighlight.id} imageBlob={createdImgBlob} imageSrc={createdImgSrc} />
      </div>
      <div className={styles.quoteshotSettingsContainer}>
        <div className={styles.lightDarkContainer}>
          <>
            <button
              type="button"
              aria-label="set-light-mode"
              onClick={setLightMode}
              disabled={!currentQuoteshotTheme?.hasDarkMode}
              className={`${styles.settingsButton} ${styles.ratioButton} ${!isDarkMode ? styles.buttonSelected : ''}`}
            >
              <LightThemeIcon className={`${styles.lightDarkIcon} ${!isDarkMode ? styles.lightDarkIconActive : ''}`} />
            </button>
            <button
              type="button"
              aria-label="set-dark-mode"
              onClick={setDarkMode}
              disabled={!currentQuoteshotTheme?.hasDarkMode}
              className={`${styles.settingsButton} ${isDarkMode ? styles.buttonSelected : ''}`}
              style={{ marginRight: 24 }}
            >
              <DarkThemeIcon className={`${styles.lightDarkIcon} ${isDarkMode ? styles.lightDarkIconActive : ''}`} />
            </button>
          </>
          <>
            <button
              type="button"
              aria-label="set-sans-serif-font"
              onClick={setSansSerif}
              className={`${styles.settingsButton} ${styles.sansSerifButton} ${currentFont === QuoteshotFont.SansSerif ? styles.buttonSelected : ''}`}
            >
              Aa
            </button>
            <button
              type="button"
              aria-label="set-serif-font"
              onClick={setSerif}
              className={`${styles.settingsButton} ${styles.serifButton} ${currentFont === QuoteshotFont.Serif ? styles.buttonSelected : ''}`}
            >
              Aa
            </button>
          </>
        </div>
        <div className={styles.variantContainer}>
          {currentThemeVariants.map((themeVariant) =>
            <button
              type="button"
              aria-label="set-variant"
              key={themeVariant.id}
              className={`${styles.variantButton} ${selectedVariantId === themeVariant.id ? styles.buttonSelected : ''}`}
              onClick={() => selectVariant(themeVariant.id)}
            >
              {themeVariant.previewImageUrl ? <img
                alt={`theme-preview-${themeVariant.id}`}
                src={getThemePropertyWithDarkMode(themeVariant, 'previewImageUrl', isDarkMode)}
                className={styles.variantButtonImage}
                style={{ filter: getThemePropertyWithDarkMode(themeVariant, 'previewImageFilter', isDarkMode) }}
              />
                : null
              }
              <div className={styles.variantButtonContent} style={{ backgroundColor: getThemePropertyWithDarkMode(themeVariant, 'previewColor', isDarkMode) }} />
            </button>)}
        </div>
        <div className={styles.ratioContainer}>
          <button
            type="button"
            aria-label="set-landscape"
            onClick={() => updateRatio(QuoteshotAspectRatio.Landscape)}
            className={`${styles.settingsButton} ${styles.ratioButton} ${aspectRatio === QuoteshotAspectRatio.Landscape ? styles.buttonSelected : ''}`}
          >
            <div className={`${styles.ratioIcon} ${styles.landscapeRatioButton}`} />
          </button>
          <button
            type="button"
            aria-label="set-portrait"
            onClick={() => updateRatio(QuoteshotAspectRatio.Portrait)}
            className={`${styles.settingsButton} ${styles.ratioButton} ${aspectRatio === QuoteshotAspectRatio.Portrait ? styles.buttonSelected : ''}`}
          >
            <div className={`${styles.ratioIcon} ${styles.portraitRatioIcon}`} />
          </button>
          <button
            type="button"
            aria-label="set-square"
            onClick={() => updateRatio(QuoteshotAspectRatio.Square)}
            className={`${styles.settingsButton} ${styles.ratioButton} ${aspectRatio === QuoteshotAspectRatio.Square ? styles.buttonSelected : ''}`}
          >
            <div className={`${styles.ratioIcon} ${styles.squareRatioIcon}`} />

          </button>
        </div>
      </div>
    </div>
  </div>;
}
